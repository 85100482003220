<template>
  <div>
    <div class="d-flex pointer" @click="$router.back()">
      <rm-base-icon class="mr-3" name="arrow-left" />
      <p>Go Back</p>
    </div>
    <b-row class="mt-5">
      <b-col cols="12">
        <pending-payment-table />
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import PendingPaymentTable from "./components/PendingPaymentTable.vue";
  export default {
    components: {
      PendingPaymentTable,
    },
    data: () => ({}),
  };
</script>
