<template>
  <div class="bg-white p-4">
    {{ checkUser }}
    <!-- <b-row no-gutters>
      <b-col cols="3">
        <rm-input
          outlined
          class="mr-2"
          preppend-icon-inner="search"
          placeholder="Search for location"
        ></rm-input>
      </b-col>
      <b-col cols="1">
        <rm-base-button text="Search"></rm-base-button>
      </b-col>
      <b-col cols="6" class="ml-4">
        <rm-base-button
          class="ml-2"
          text="Hide-Filter"
          bgColor="primary--light"
          textColor="primary"
          ><rm-base-icon slot="post-icon" class="ml-3" name="filter-icon"
        /></rm-base-button>
      </b-col>
      <b-col class="p-0">
        <div class="d-flex w-100">
          <rm-base-button
            class="ml-auto"
            text="Export"
            bgColor="primary"
            textColor="white"
            ><rm-base-icon slot="post-icon" class="ml-2" name="export-icon"
          /></rm-base-button>
        </div>
      </b-col>
    </b-row>
    <div class="filter-section">
      <b-row class="pt-3 pl-3">
        <b-col cols="3">
          <rm-input
            outlined
            class="mr-2 bg-white"
            preppend-icon-inner="person-circle"
            placeholder="By name"
          ></rm-input>
        </b-col>
        <b-col cols="3">
          <rm-input
            outlined
            class="mr-2 bg-white"
            preppend-icon-inner="telephone-fill"
            placeholder="Phone Number"
          ></rm-input>
        </b-col>
        <b-col cols="3">
          <rm-input
            outlined
            class="mr-2 bg-white"
            preppend-icon-inner="envelope-fill"
            placeholder="Email address"
          ></rm-input>
        </b-col>
        <b-col cols="3">
          <rm-input
            outlined
            class="mr-3 bg-white"
            append-icon-inner="caret-down-fill"
            preppend-icon-inner="search"
            placeholder="Status"
          ></rm-input>
        </b-col>
        <b-col cols="2" class="bordered ml-3">
          <rm-drop-down class="dropdown" outlined :options="filterOptions" />
        </b-col>
        <b-col cols="2" class="ml-2">
          <rm-drop-down outlined :options="filterOptions" />
        </b-col>
      </b-row>
      <div class="d-flex mb-5 pb-3">
        <rm-base-button
          class="ml-3"
          text="Apply Filter"
          bgColor="tertiary"
          textColor="white"
        ></rm-base-button>
        <rm-base-button
          class="ml-3"
          text="Clear Filter"
          bgColor="tertiary--light"
          textColor="tertiary"
        ></rm-base-button>
      </div>
    </div> -->
    <b-table
      ref="pendingPaymentsTable"
      thead-class="bg-gray-400 text-uppercase text-sm-2"
      :selectable="false"
      hover
      :busy="isLoading"
      :items="items"
      :fields="fields"
      @row-selected="onRowSelected"
    >
      <template #table-busy>
        <div class="text-center text-gray my-5">
          <b-spinner class="align-middle"></b-spinner> <br />
          <strong>Loading Data...</strong>
        </div>
      </template>
      <template #cell(status)="{ item }">
        <div class="px-2 py-1 rounded">
          <h6
            :class="
              item.status == 'pending'
                ? 'bg-warning--light text-warning'
                : 'text-success bg-success--light'
            "
            class="status px-2 py-2 rounded text-capitalize"
          >
            {{ item.status }}
          </h6>
        </div>
      </template>
      <template #cell(date)="{ item }">
        {{ item.date | luxon }}
      </template>

      <!-- Action column -->
      <template #cell(actions)="{ item }">
        <div class="d-flex" v-if="item.payment_mode === 'manual'">
          <a :href="item.proof_of_payment" target="_blank">
            <rm-base-button
              text="View"
              class="font-weight-bold"
              bgColor="primary"
              textColor="white"
            ></rm-base-button>
          </a>
          <rm-base-button
            v-if="item.status !== 'success'"
            :text="item.status === 'verified' ? 'approve' : 'verify'"
            class="action-verify font-weight-bold ml-2 rounded"
            bgColor="#f0eaf5"
            textColor=" #6a2a9d"
            @click="showPaymentModal(item)"
          ></rm-base-button>
        </div>
      </template>
    </b-table>
    <div class="mt-3 pagination-section">
      <h3 class="mt-2">
        Showing page 
        {{ paging.currentPage }} of 
        {{ paging.totalPages }}
      </h3>
      <b-pagination
        @change="changePage"
        v-model="paging.currentPage"
        :current-page="paging.currentPage"
        :total-rows="paging.totalRows"
        :per-page="paging.perPage"
        first-number
        last-number
        aria-controls="pendingPaymentsTable"
      ></b-pagination>
    </div>

    <rm-base-modal id="payment-modal" size="lg">
      <payment-modal :id="selected.id" @completed="loadData" />
    </rm-base-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PaymentModal from './ProcessPayment.vue';
export default {
  components: {
    PaymentModal,
  },
  data() {
    return {
      isLoading: false,
      selectAll: false,
      paging: {
        currentPage: 1,
        perPage: 10,
        totalRows: null,
        totalPages: 1
      },
      selected: {},
      // Note `isActive` is left out and will not appear in the rendered table
      items: [],
      fields: [
        {
          key: 'check',
          label: '',
        },
        {
          key: 'client',
          label: 'client',
        },
        {
          key: 'amount',
          sortable: false,
          label: 'amount paid',
          class: 'text-center',
        },
        {
          key: "payment_mode",
          sortable: false,
          label: "payment mode",
          class: "text-center",
        },
        {
          key: "referrer",
          sortable: false,
          label: "referrer",
          class: "text-center",
        },
        {
          key: "date",
          sortable: false,
          label: "date",
          class: "text-center",
        },
        {
          key: 'description',
          sortable: false,
          label: 'Description',
          class: 'w-10',
        },
        {
          key: "status",
          sortable: false,
          label: "Status",
          class: "text-center",
        },
        {
          key: 'actions',
          sortable: false,
          label: 'actions',
        },
      ],
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async showPaymentModal(item) {
      this.selected = item;
      await this.$store.dispatch(
        'payments/fetchPaymentById',
        item.id
      );
      this.$bvModal.show('modal-lg-payment-modal');
    },
    changePage(pageNo) {
      this.paging.currentPage = pageNo;
      this.loadData();
    },
    async loadData() {
      this.isLoading = true;
      try {
        const params = {
          page: this.paging.currentPage,
        }
        const {
          rows, paging
        } = await this.fetchPayments(params);
        this.items = this.extractPaymentData(rows);
        this.assignPagingData(paging);
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false;
      }
    },
    async fetchPayments(params) {
      const data = await this.$store.dispatch(
        'payments/fetchPendingPayments',
        params,
      );
      return data;
    },
    extractPaymentData(data) {
      return data.map((item) => {
        const { user } = item;
        return {
          id: item.id,
          client: `${user.last_name} ${user.first_name}`,
          amount: `₦${this.$formatCurrency(item.amount / 100)}`,
          payment_mode: item.payment_method,
          referrer: `${user.upline.first_name} ${user.upline.last_name}`,
          date: item.created_at,
          description: item.description,
          status: item.status,
          proof_of_payment: item.proof_of_payment,
        }
      });
    },
    assignPagingData(data) {
      this.paging.currentPage = data.currentPageNo;
      this.paging.perPage = data.perPage;
      this.paging.totalRows = data.total;
      this.paging.totalPages = data.totalNoPages; 
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.pendingPaymentsTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.pendingPaymentsTable.clearSelected();
    },
    selectRow(index) {
      this.$refs.pendingPaymentsTable.selectRow(index);
    },
    unSelectRow(index) {
      this.$refs.pendingPaymentsTable.unselectRow(index);
    },
    handleRowSelect(isRowSelected, index) {
      if (!isRowSelected) {
        this.selectRow(index);
      } else {
        this.unSelectRow(index);
      }
    },
  },
  computed: {
    ...mapState('auth', ['userObj']),
    userRoles() {
      return  this.userObj.role.scopes ?? []
    },
    checkUser(){
      if (this.userRoles.includes('payment.verify')){
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    selectAll(v) {
      if (!v) {
        this.clearSelected();
      } else {
        this.selectAllRows();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.hidden {
  display: none;
}
.status {
  font-size: 0.8rem;
}
.bordered {
  border-left: 1px solid var(--primary);
}
.filter-section {
  background: #f6f8f8;
}
.dropdown {
  font-size: 0.2rem;
}
.pagination-section {
  display: flex;
  padding: 1rem 2rem;
  border: solid grey 0.1px;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
}
.pagination-section h3 {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8125rem;
  // line-height: 1.63rem;
}
</style>
